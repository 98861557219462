import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import PersonImage from '../assets/person.png'
import MapImage from '../assets/map.png'

class About extends React.Component {
  render() {
    return (
      <Layout>
        <Seo title="About" />
        <Container className="section">
          <Row>
            <Col xs={12} sm={6}>
              <h1>Dedicated to serving our clients</h1>
              <p>
                PAR Global, headquartered in Georgia-USA, We are one stop
                manufacturing solution and service provider for Best in Class
                Synthetic and Natural fibre textiles, Industrial and Retail
                Packaging products, Chemicals, Steel and related Accessories.
                With over 100 years of combined experience and success in
                manufacturing, marketing and sales, we bring world class
                products to you as a partner in your success to build a
                partnership that lasts for generations.
              </p>
              <p>
                <span className="text-gold">
                  Integrity, ownership, excellence, passion and customer
                  satisfaction
                </span>{' '}
                are our core values.
              </p>
            </Col>
            <Col xs={12} sm={6}>
              <img
                src="https://images.ctfassets.net/ktvuei6pywef/Kk8cUhhZIEzdMtTkVk1qQ/bb61e3cc2aae0724ced36bd3dae927c2/ParGlobal_AboutUs_Photo.jpg?h=500"
                alt="par global people working"
                style={{ maxHeight: '450px', objectFit: 'contain' }}
              ></img>
            </Col>
          </Row>
        </Container>
        <Container className="section section-gray" fluid>
          <Container>
            <div className="mt-5">
              <h1>What makes us successful?</h1>
            </div>
            <Row>
              <Col xs={12} sm={6}>
                <ul className="fancy-list">
                  <li>
                    Having multiple manufacturing facilities strategically
                    located within India, which meets requirements of several
                    international certification standards including ISO 9001 &
                    14001, Food Safety, AASHTO NTPEP and CE standards.
                  </li>
                  <li>
                    Full range of products in segments such as erosion control,
                    civil & environmental construction, landscaping, small &
                    bulk packaging and industrial chemicals.
                  </li>
                  <li>
                    Dedicated Technical services to assist customer in product
                    selection, design and installation. Knowledgeable customer
                    support department to cater to customer needs as they arise.
                  </li>
                </ul>
              </Col>
              <Col xs={12} sm={6}>
                <ul className="fancy-list">
                  <li>
                    Single platform order placement system powered by AI, for
                    order placement and tracking facility.
                  </li>
                  <li>
                    Innovative and modern supply chain management. Offering
                    Consolidation Of Multiple Products On A Single Container.
                  </li>
                  <li>
                    Ensuring that every raw material used in production every
                    time is of high quality, combined with our in house
                    stringent quality check and quality assurance capabilities
                    means outstanding quality products.
                  </li>
                  <li>
                    Customizable size, color and printing options available to
                    meet any job specific requirements. Wide Widht Products
                    Offered In Folded Forms For Siginificant Savings On LTL
                    Freights.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container className="section">
          <Image src={MapImage}></Image>
        </Container>
        <Container className="section">
          <Row>
            <h1>Meet our team</h1>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Yogi Parikh</h4>
                <span>-</span>
                <p>President</p>
                <p className="gray">yogi@parglobal.us</p>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Monte Thomas</h4>
                <span>-</span>
                <p>Vice President</p>
                <p className="gray">mthomas@parglobal.us</p>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Marshall Gaddy</h4>
                <span>-</span>
                <p>Quality Control Manager</p>
                <p className="gray">mgaddy@parglobal.us</p>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Raj Gadhavi</h4>
                <span>-</span>
                <p>Business Development</p>
                <p className="gray">rgadhavi@parglobal.us</p>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Bharat Gandhi</h4>
                <span>-</span>
                <p>Finance Controller</p>
                <p className="gray">accounts@parglobal.us</p>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Anokhi Parikh</h4>
                <span>-</span>
                <p>Marketing & Events Coordinator</p>
                <p className="gray">aparikh@parglobal.us</p>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Nichloas Smith</h4>
                <span>-</span>
                <p>Sales</p>
                <p className="gray">nsmith@parglobal.us</p>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Pankaj Rajput</h4>
                <span>-</span>
                <p>Supply Chain Manager</p>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Anil Nikam</h4>
                <span>-</span>
                <p>Logistics Manager</p>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="contact-card">
                <Image roundedCircle src={PersonImage}></Image>
                <h4>Cornelius Thorpe</h4>
                <span>-</span>
                <p>Warehouse Manager</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default About
